@import "./screens";

/*=====================================
=            MEDIA QUERIES            =
=====================================*/
/// Media Query mixin for extra small mobiles 0 > 359
///@content CSS blocks on the desired element or if used by itself you can play selectors inside
///@output It outputs the typed mediaquery and its css blocks if used inside an element
///@example
/// input {
///   font-size:32px;
///   @include mobile-small {
///     font-size:12px;
///     }
/// }

@mixin mobile-small {
  @media only screen and (max-width: $screen-xxxs) {
    @content;
  }
}

/// Media Query mixin for mobiles 0 > 767
///@content CSS blocks on the desired element or if used by itself you can play selectors inside
///@output It outputs the typed mediaquery and its css blocks if used inside an element
///@example
/// input {
///   font-size:32px;
///   @include mobile {
///     font-size:12px;
///     }
/// }

@mixin mobile {
  @media only screen and (max-width: $screen-sm) {
    @content;
  }
}

/// Media Query mixin for small tablets 767 > 992
///@content CSS blocks on the desired element or if used by itself you can play selectors inside
///@output It outputs the typed mediaquery and its css blocks if used inside an element
///@example
/// input {
///   font-size:32px;
///   @include tablet {
///     font-size:12px;
///     }
/// }

@mixin tablet {
  @media only screen and (min-width: $screen-sm) and (max-width: $screen-md) {
    @content;
  }
}

/// Media Query mixin for big tablets 992 > 1199
///@content CSS blocks on the desired element or if used by itself you can play selectors inside
///@output It outputs the typed mediaquery and its css blocks if used inside an element
///@example
/// input {
///   font-size:32px;
///   @include tablet-big {
///     font-size:12px;
///     }
/// }
@mixin tablet-big {
  @media only screen and (min-width: $screen-md) and (max-width: $screen-lg) {
    @content;
  }
}

/// Media Query mixin for laptop devices less then 1200px
///@content CSS blocks on the desired element or if used by itself you can play selectors inside
///@output It outputs the typed mediaquery and its css blocks if used inside an element
///@example
/// input {
///   font-size:32px;
///   @include laptop {
///     font-size:42px;
///     }
/// }
@mixin laptop {
  @media only screen and (max-width: $screen-lg) {
    @content;
  }
}
/// Media Query mixin for desktop devices over 1200px > 1700px
///@content CSS blocks on the desired element or if used by itself you can play selectors inside
///@output It outputs the typed mediaquery and its css blocks if used inside an element
///@example
/// input {
///   font-size:32px;
///   @include desktop {
///     font-size:42px;
///     }
/// }
@mixin desktop {
  @media only screen and (min-width: $screen-lg) and (max-width: $screen-xl) {
    @content;
  }
}
/// Media Query mixin for desktop devices 1700px  > 1920px
///@content CSS blocks on the desired element or if used by itself you can play selectors inside
///@output It outputs the typed mediaquery and its css blocks if used inside an element
///@example
/// input {
///   font-size:32px;
///   @include desktop-big {
///     font-size:42px;
///     }
/// }
@mixin desktop-big {
  @media only screen and (min-width: $screen-xl) and (max-width: $screen-xxl) {
    @content;
  }
}
/// Media Query mixin for desktop devices 1920px > 2500px
@mixin screen-xxl {
  @media only screen and (min-width: $screen-xxl) and (max-width: $screen-2k) {
    @content;
  }
}

/// Media Query mixin for desktop devices with minimum width of 2500px
@mixin screen-2k {
  @media only screen and (min-width: $screen-2k) {
    @content;
  }
}
/// Media Query mixin for desktop devices with minimum width of 3800px
@mixin screen-4k {
  @media only screen and (min-width: $screen-4k) {
    @content;
  }
}
