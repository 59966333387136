$primary-color: #1e2945;
$secondary-color: #76a3da;
$tertiary-color: #77829e;
$white: #ffffff;
$green: #0caf60;
$black: #000000;

$border: #e8e8e8;
$silver-sand: #c4c4c4;
$error: #e9635e;
