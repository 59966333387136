@import "src/styles/lib";

.Email {
  &__container {
    padding: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@include mobile {
  .Email {
    &__container {
      padding: 20px 0 20px 0;
    }
  }
}
