@import "src/styles/lib";

.InputField {
  display: inline-block;
  width: 100%;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border: 1px solid $silver-sand;
    border-radius: 8px;
    &--disabled {
      background-color: lighten($silver-sand, 20);
      cursor: not-allowed;
    }

    &:focus-within {
      border: 1px solid darken($silver-sand, 20);
      background-color: transparent;
    }

    &--error {
      border: 1px solid $error;
    }

    &--errorMessage {
      font-size: 12px;
      text-align: right;
      padding: 5px;
      color: $error;
      line-height: 1em;
    }
  }

  &__input {
    border-radius: 8px;
    width: 100%;
    height: calc(100% - 10px);
    font-size: 14px;
    color: $black;
    outline: none;
    border: none;
    padding-left: 16px;
    padding-right: 16px;

    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s;
    }

    &--disabled {
      background-color: lighten($silver-sand, 20);
      cursor: not-allowed;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $black;
    margin-left: 16px;
    padding-right: 16px;

    &:hover {
      cursor: pointer;
    }

    > svg {
      width: 16px;
      height: 16px;
    }
  }
}
