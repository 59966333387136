@import "src/styles/lib";

.FormCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: $white;
  width: 451px;
  border: 1px solid $border;
  border-radius: 8px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid $border;

    &-logo {
      width: 100px;
      color: $primary-color;
      padding-bottom: 6px;
    }

    &-title {
      margin-left: 10px;
      padding-left: 10px;
      font-size: 14px;
      font-weight: bold;
      color: $tertiary-color;
      border-left: 1px solid $black;
    }
  }

  &__container {
    width: 100%;
  }

  &__content {
    margin: 50px 60px 50px 60px;
  }
}

@include mobile {
  .FormCard {
    margin: 0 20px;

    &__content {
      margin: 50px 20px 50px 20px;
    }
  }
}
