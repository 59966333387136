@import "src/styles/lib";

.MessageTemplate {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__icon {
    width: 60px;
    margin-bottom: 30px;
  }

  &__title {
    font-size: 20px;
    color: $black;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }

  &__paragraph {
    font-size: 16px;
    color: $tertiary-color;
    text-align: center;
    margin: 0 40px 35px 40px;
  }
}
