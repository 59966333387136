@import "src/styles/lib";

.Button {
  position: relative;
  min-width: 150px;
  height: 48px;
  font-size: 14px;
  border-radius: 8px;
  border: none;
  padding: 10px 25px;
  line-height: 15px;
  letter-spacing: 0;
  font-family: $proxima-nova;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $primary-color;
  color: $white;

  &:hover {
    background: darken($primary-color, 10%);
  }

  &:hover > &__tooltip {
    visibility: visible;
    transition: 0.5s;
  }

  &__left--icon {
    margin-right: 9px;
    > svg {
      height: 12px;
      width: 12px;
    }
  }
  &__right--icon {
    margin-left: 9px;
    > svg {
      height: 12px;
      width: 12px;
    }
  }

  &--disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
  }
}
